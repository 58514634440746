t<template>
  <div>
    <b-card no-body class="mb-0 p-1">
      <b-table
        show-empty
        :sort-by.sync="sortBy"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        :busy="isBusy"
        :responsive="true"
        outlined
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>読み込み中...</strong>
          </div>
        </template>
        <template #cell(createdAt)="data">
          {{formatDateString(data.item.createdAt)}}
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
      </b-table>
      <div class="mx-2 mt-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BSpinner,
  BFormCheckbox,
  BFormSelect,
  BFormFile,
} from "bootstrap-vue";

import Checkbox from "primevue/checkbox";
import UserFilter from "./UserFilter.vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "この項目は必須です。",
});

extend("passwordlength", (value) => {
  if (value.length >= 8) return true;

  return "パスワードは8文字以上である必要があります。";
});

extend("phonenumber", (value) => {
  let isPhoneValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
    value
  );
  if (isPhoneValid) return true;

  return "有効な電話番号を入力して下さい。";
});

extend("email", {
  ...email,
  message: "有効なメールアドレスを入力してください。",
});

export default {
  data() {
    return {
      fileExcel: null,
      sortBy: "email",
      roles: [],
      items: [],
      isMounted: false,
      isAdd: true,
      filterBy: "",
      fields: [
        {
          key: "index",
          label: "No.",
        },
        {
          key: "fullName",
          label: "氏名",
          sortable: true,
        },
        {
          key: "email",
          label: "メールアドレス",
        },
        {
          key: "createdAt",
          label: "作成日付",
        },
        {
          key: "description",
          label: "内容",
        },
        {
          key: "note",
          label: "備考",
     thStyle: { "min-width": "100px" },
        },
      ],
      currentPage: 1,
      totalUsers: 1,
      perPage: 10,
      totalItems: 1,
      filter: "",
      currentUserId: null,
      queryString: "",
      isBusy: true,
    };
  },
  components: {
    BFormFile,
    BFormSelect,
    BFormCheckbox,
    Checkbox,
    BButton,
    BTable,
    BPagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    UserFilter,
  },
  methods: {
      formatDateString(date) {
    let includeTime = 'ja-JP';
  if (date) {
    const d = new Date(date);
    
    if (includeTime) {
      return d.toLocaleDateString(includeTime) ;
    }

    return d.toLocaleDateString(includeTime);
  }

  return "";
},
    async handleSearch() {
      this.isBusy = true;
      await this.getData();
      this.isBusy = false;
    },
    async getData() {
      try {
        let payload = {
          PageIndex: this.currentPage,
          PageSize: 10,
          FilterBy: this.filterBy,
        };

        let res = await axios.get(`CustomUser/log-action`, { params: payload });

        this.items = res.data.data.items;
        this.totalUsers = res.data.data.totalCount;
      } catch {}
    },
    async handleFilter() {
      this.isBusy = true;
      await this.getData();
      this.isBusy = false;
    },
  },
  async mounted() {
    this.isBusy = true;
    await this.getData();
    this.isBusy = false;
    this.isMounted = true;
    this.getRoles();
  },
  watch: {
    currentPage: {
      handler: async function(value) {
        this.isBusy = true;
        await this.getData();
        this.isBusy = false;
      },
    },
  },
};
</script>

<style>
#btn-search {
  padding: 0.785rem 1.4rem !important;
  border-color: #ccc;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
#input-search {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.input-error {
  color: #f22435;
  display: block;
  margin-top: 5px;
  margin-left: 2px;
}
</style>
